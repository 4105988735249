































import '../scss/index.scss';
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Button } from '@frontiers-vue/button';
import Configuration from '../core/Configuration';

@Component({
  components: { Button },
})
export default class Success extends Vue {

  private brandName: string = Configuration.Instance.BrandName;
  private subscriptionType: string = Configuration.Instance.SubscriptionType;
  private resourceValue: string = Configuration.Instance.ResourceValue;
  private errorMessage: string = Configuration.Instance.ErrorMessage;
  private noError: boolean = true;
  private preferencesKey: string = Configuration.Instance.UrlKey;
  private subscriptionTypeText: string = '';
  private wasGloballyUnsubscribed: boolean = Configuration.Instance.WasGloballyUnsubscribed;

  private created(): void {

    if (this.errorMessage !== undefined && this.errorMessage !== null && this.errorMessage !== '' && this.errorMessage.trim().length > 0) {
      this.noError = false;
    }
    else {
      this.noError = true;
      if (this.resourceValue !== undefined && this.resourceValue !== null && this.resourceValue.trim().length > 0) {
        this.subscriptionTypeText = this.subscriptionType + ' for ' + this.resourceValue;
      } else {
        this.subscriptionTypeText = this.subscriptionType;
      }
    }
  }
  
  private goToPreferences(): void {
    window.location.href = '/emails/preferences/' + encodeURIComponent(this.preferencesKey);
  }

}
